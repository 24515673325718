import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Reset page position to top of page when location (current URL) changes.
const useScrollReset = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

export default useScrollReset;
