import axios from 'axios';

const baseURL =
  process.env.REACT_APP_BACKEND_URL ||
  'https://dev1-api.swap-box-dev.com/api/v1';

// https://dev1-api.swap-box-dev.com/api/v1
const axiosInstance = axios.create({
  baseURL,
});

// Setup response interceptors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const refreshToken = localStorage.getItem('refreshToken');
    const url = error?.response?.config.url;
    // If refresh token is present, and response is status code 401 (unauthorized), and the http request url is not user/refresh or user/verify
    // then try to call user/refresh to update both refresh and access token.
    if (
      refreshToken &&
      error?.response?.status === 401 &&
      !originalRequest.retry &&
      !['/users/refresh', '/users/verify'].includes(url)
    ) {
      originalRequest.retry = true;
      return axiosInstance
        .post('/users/refresh', { refresh: refreshToken })
        .then((res) => {
          localStorage.setItem('accessToken', res.data.access);
          localStorage.setItem('refreshToken', res.data.refresh);
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
          return axiosInstance({
            ...originalRequest,
            headers: {
              ...originalRequest.headers,
              Authorization: `Bearer ${res.data.access}`,
            },
          });
        });
    }
    return Promise.reject(
      (error.response && error.response.data) || {
        detail: 'Something went wrong',
      }
    );
  }
);

const axiosMockInstance = axios.create();

axiosMockInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export { axiosInstance, axiosMockInstance, baseURL };
