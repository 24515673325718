import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import SplashScreen from '../components/SplashScreen';
import { axiosInstance as axios } from '../utils/axios';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  name: '',
};

const setSession = (accessToken, refreshToken) => {
  if (refreshToken) {
    // If refresh token IS NOT null, set it in local storage.
    localStorage.setItem('refreshToken', refreshToken);
  }
  if (accessToken) {
    // If access token IS NOT null, set it in local storage.
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    // If access token IS null, remove any tokens that are in the local storage.
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// Setup the reducer, containing the different auth states, which can be accessed through the intended dispatch methods.
const reducer = (state, action) => {
  const { isAuthenticated, name } = action.payload || {};

  switch (action.type) {
    case 'INITIALISE': {
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        name,
      };
    }
    case 'LOGIN': {
      return {
        ...state,
        isAuthenticated: true,
        name,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case 'REGISTER': {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  // Login function.
  const login = async (email, password) => {
    try {
      // Clear any tokens in local storage.
      setSession(null);
      // Login http request
      const response = await axios.post('/users/login', {
        email,
        password,
      });
      // Format response.
      const {
        status,
        data: { access, refresh, role, name },
      } = response;

      // If account IS NOT admin, deny access, and set error.
      if (role !== 'Admin') {
        return {
          error: true,
          message: 'No active account found with the given credentials',
        };
      }

      // If account IS admin, and login http request is successful.
      if (status === 200) {
        // Set tokens in local storage.
        setSession(access, refresh);
        // Change the state of the reducer with this dispatch method.
        dispatch({
          type: 'LOGIN',
          payload: {
            isAuthenticated: true,
            name,
          },
        });
      }
      // Return successful response obj.
      return { ...response.data, error: false };
    } catch (e) {
      console.error(e);
      // Return unsuccessful response obj.
      return { error: true, message: e.detail };
    }
  };

  const logout = () => {
    // Clear any tokens in local storage.
    setSession(null, null);
    // Change the state of the reducer with this dispatch method.
    dispatch({ type: 'LOGOUT' });
  };

  const register = () => {};

  // On mount, and everytime the state changes
  useEffect(() => {
    const initialise = async () => {
      let accessToken = window.localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      let isAuthenticated = false;
      let userData = {};

      // If access token present, make verification http request. If valid, store access token in local storage.
      if (accessToken) {
        try {
          const response = await axios.post('/users/verify', {
            token: accessToken,
          });
          isAuthenticated = response.status === 200;
          if (isAuthenticated) setSession(accessToken);
        } catch (err) {
          console.error(err);
          isAuthenticated = false;
        }
      }

      try {
        // If state is currently not authenticated but refresh token is present, use refresh token to get new access and refresh token.
        // If successful, store tokens in local storage and get user data.
        if (!isAuthenticated && refreshToken) {
          const response = await axios.post('/users/refresh', {
            refresh: refreshToken,
          });
          if (response.status === 200) {
            const { access, refresh } = response.data;
            setSession(access, refresh);
            accessToken = access;
            isAuthenticated = true;
          }
        }
        const { user_id: userId } = jwtDecode(accessToken);
        const { data } = await axios.get(`/users/${userId}`);
        userData = { name: data.name };
      } catch (e) {
        console.error(e);
        isAuthenticated = false;
      }

      dispatch({
        type: 'INITIALISE',
        payload: {
          ...userData,
          isAuthenticated,
        },
      });
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthContext;
