export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const PHONE_REGEX =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const ALPHANUMERIC_REGEX = /^[ a-zA-Z0-9_-]*$/;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
};

export const LANGUAGES = {
  English: 'en',
  French: 'fr',
  Dutch: 'nl',
};

export const initialCenter = { lat: 52.377956, lng: 4.89707 };
