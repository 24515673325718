/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath, Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Share2 as ShareIcon,
  Users as UsersIcon,
  MapPin,
  Package,
  Map as MapIcon,
  Box as BoxIcon,
  CornerLeftDown,
} from 'react-feather';
import RestaurantIcon from '@material-ui/icons/RestaurantOutlined';
import ListAltIcon from '@material-ui/icons/ListAltOutlined';
import CropFreeIcon from '@material-ui/icons/CropFreeOutlined';
import LiveHelpIcon from '@material-ui/icons/LiveHelpOutlined';
import HighlightIcon from '@material-ui/icons/HighlightOutlined';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from 'react-i18next';
import Logo from '../../../components/Logo';
import NavItem from './NavItem';

const getSections = (t) => [
  {
    subheader: t('navBar.sections.management.title'),
    items: [
      {
        title: t('navBar.sections.management.items.customers'),
        icon: UsersIcon,
        href: '/app/management/customers',
      },
      {
        title: t('navBar.sections.management.items.restaurants'),
        icon: RestaurantIcon,
        href: '/app/management/restaurants',
      },
      {
        title: t('navBar.sections.management.items.dropOffPoints'),
        icon: MapPin,
        href: '/app/management/drop-off-points',
      },
      {
        title: t('navBar.sections.management.items.invoiceManagement'),
        icon: DescriptionIcon,
        href: '/app/management/invoiceManagement',
      },
      {
        title: t('navBar.sections.management.items.invoices'),
        icon: ReceiptIcon,
        href: '/app/management/invoices',
      },
      {
        title: t('navBar.sections.inventory.items.boxTypes'),
        icon: ListAltIcon,
        href: '/app/management/boxTypes',
      },
    ],
  },
  {
    subheader: t('navBar.sections.inventory.title'),
    items: [
      {
        title: t('navBar.sections.inventory.items.stock'),
        icon: Package,
        href: '/app/management/stock',
      },
      {
        title: t('navBar.sections.inventory.items.returnStock'),
        icon: CornerLeftDown,
        href: '/app/management/stock/return',
      },
      {
        title: t('navBar.sections.inventory.items.boxes'),
        icon: BoxIcon,
        href: '/app/management/boxes',
      },

      {
        title: t('navBar.sections.inventory.items.barcode'),
        icon: CropFreeIcon,
        href: '/app/management/barcodes',
      },
    ],
  },
  {
    subheader: t('navBar.sections.applications.title'),
    items: [
      {
        title: t('navBar.sections.applications.items.locator'),
        href: '/app/locator',
        icon: MapIcon,
      },
      {
        title: t('navBar.sections.applications.items.loyaltyProgram'),
        href: 'https://www.swap-box.com/loyalty-program',
        isExternalLink: true,
        target: '_blank',
        icon: ShareIcon,
      },
      {
        title: t('navBar.sections.applications.items.howItWorks'),
        href: 'https://www.swap-box.com/how-it-works',
        isExternalLink: true,
        target: '_blank',
        icon: HighlightIcon,
      },
      {
        title: t('navBar.sections.applications.items.faq'),
        href: 'https://www.swap-box.com/faq',
        isExternalLink: true,
        target: '_blank',
        icon: LiveHelpIcon,
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
          }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        isExternalLink={item.isExternalLink}
        target={item.target}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  logoIcon: {
    height: 50,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const sections = getSections(t);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logoIcon} />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
