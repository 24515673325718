import React, { Suspense, Fragment, lazy } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';
import LoadingScreen from './components/LoadingScreen';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i} // eslint-disable-line react/no-array-index-key
            path={route.path}
            exact={route.exact}
            element={
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    path: '/app/*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView')),
      },
      {
        exact: true,
        path: '/management/customers/:customerId/stock',
        component: lazy(() => import('src/views/customer/CustomerStockView')),
      },
      {
        exact: true,
        path: '/management/stock/create',
        component: lazy(() => import('src/views/stock/StockCreateView')),
      },
      {
        exact: true,
        path: '/management/invoices',
        component: lazy(() => import('src/views/invoice/InvoiceListView')),
      },
      {
        exact: true,
        path: '/management/drop-off-points',
        component: lazy(() =>
          import('src/views/dropOffPoint/DropOffPointsListView')
        ),
      },
      {
        exact: true,
        path: '/management/boxTypes/',
        component: lazy(() => import('src/views/boxType/BoxTypeListView')),
      },
      {
        exact: true,
        path: '/management/boxTypes/:typeId/edit',
        component: lazy(() => import('src/views/boxType/BoxTypeEditView')),
      },
      {
        exact: true,
        path: '/management/boxTypes/create',
        component: lazy(() => import('src/views/boxType/BoxTypeCreateView')),
      },
      {
        exact: true,
        path: '/management/drop-off-points/create',
        component: lazy(() =>
          import('src/views/dropOffPoint/DropOffPointsCreateView')
        ),
      },
      {
        path: '/management/drop-off-points/create',
        component: lazy(() =>
          import('src/views/dropOffPoint/DropOffPointsCreateView')
        ),
      },
      {
        exact: true,
        path: '/management/drop-off-points/:dropOffPointId/edit',
        component: lazy(() =>
          import('src/views/dropOffPoint/DropOffPointsEditView')
        ),
      },
      {
        exact: true,
        path: '/management/barcodes/',
        component: lazy(() => import('src/views/barcode/BarcodeListView')),
      },
      {
        exact: true,
        path: '/management/barcodes/:id',
        component: lazy(() => import('src/views/barcode/BarcodeDetailsView')),
      },
      {
        exact: true,
        path: '/management/boxes',
        component: lazy(() => import('src/views/boxes/boxesListView')),
      },
      {
        exact: true,
        path: '/management/boxes/:barcode/edit',
        component: lazy(() => import('src/views/boxes/boxesEditView')),
      },
      {
        exact: true,
        path: '/management/barcode/create',
        component: lazy(() => import('src/views/barcode/barcodeCreateView')),
      },
      {
        exact: true,
        path: '/management/restaurants',
        component: lazy(() =>
          import('src/views/restaurant/RestaurantListView')
        ),
      },
      {
        exact: true,
        path: '/management/restaurants/create',
        component: lazy(() =>
          import('src/views/restaurant/RestaurantCreateView')
        ),
      },
      {
        exact: true,
        path: '/management/restaurants/:restaurantId/edit',
        component: lazy(() =>
          import('src/views/restaurant/RestaurantEditView')
        ),
      },
      {
        exact: true,
        path: '/management/restaurants/:restaurantId/stock',
        component: lazy(() =>
          import('src/views/restaurant/RestaurantStockView')
        ),
      },
      {
        exact: true,
        path: '/management/stock',
        component: lazy(() => import('src/views/stock/StockListView')),
      },
      {
        exact: true,
        path: '/app/management/stock/create',
        component: lazy(() => import('src/views/stock/StockCreateView')),
      },
      {
        exact: true,
        path: '/management/stock/return',
        component: lazy(() => import('src/views/stock/StockCreateView')),
      },
      {
        exact: true,
        path: '/management',
        component: () => <Navigate to="/app/management/customers" />,
      },
      {
        exact: true,
        path: '/locator/',
        component: lazy(() => import('src/components/map')),
      },
      {
        exact: true,
        path: '/management/invoiceManagement',
        component: lazy(() =>
          import('src/views/invoiceManagement/InvoiceManagementListView')
        ),
      },
      {
        exact: true,
        path: '/management/invoiceManagement/create',
        component: lazy(() =>
          import('src/views/invoiceManagement/InvoiceManagementCreateView')
        ),
      },
      {
        exact: true,
        path: '/management/invoiceManagement/:invoiceId/edit',
        component: lazy(() =>
          import('src/views/invoiceManagement/InvoiceManagementEditView')
        ),
      },
      {
        exact: true,
        path: '/',
        component: () => <Navigate to="/app/management/customers" />,
      },
      {
        component: () => <Navigate to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        guard: GuestGuard,
        component: lazy(() => import('src/views/auth/LoginView')),
      },
      {
        exact: true,
        path: '/password-reset/:uidb64/:token',
        component: lazy(() => import('src/views/auth/ResetPasswordView')),
      },
      {
        exact: true,
        path: '/forgot-password',
        component: lazy(() => import('src/views/auth/ForgotPasswordView')),
      },
      {
        component: () => <Navigate to="/404" />,
      },
    ],
  },
];

export default routes;
