import React, { useRef, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Hidden,
  Menu,
  MenuItem,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

// Element styilng.
const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  username: {
    color: theme.palette.text.white,
  },
}));

const Account = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { name: username } = useAuth();
  const ref = useRef(null);
  const { logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);
  
  // Show additional nav items (on desktop).
  const handleOpen = () => {
    setOpen(true);
  };
  
  // Hide additional nav items (on desktop).
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
          src="/static/images/avatars/avatar_6.png"
        />
        <Hidden smDown>
          <Typography variant="h6" className={classes.username}>
            {username}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/app/account">
          {t('topBar.account')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('topBar.logout')}</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
