import React from 'react';
import PropTypes from 'prop-types';

// Return white or black logo depending on params
const Logo = ({ isWhite, ...props }) => {
  const imagePath = isWhite
    ? '/static/swap_box_white.png'
    : '/static/swap_box_black.png';
  return <img alt="Logo" src={imagePath} {...props} />;
};

Logo.propTypes = {
  isWhite: PropTypes.bool,
};

export default Logo;
